import React from "react";
import { AppBar as MuiAppBar, Hidden } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import Logo from "./Logo";
import { Link } from "react-scroll";
import theme, { colors } from "../theme"
import styled from "styled-components";
import Links from "../const/Links"
import { Link as GatsbyLink } from 'gatsby'

const StyledLink = styled(Link)`
  cursor: pointer;
  color: #2E2E2E;
`

const Navbar = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);

  return (
    <MuiAppBar
      position="sticky"
      color="secondary"
      elevation={0}
      style={{ zIndex: 1400 }}
    >
      <Container maxWidth="xl">
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            md={10}
            container
            alignItems="center"
            justify="space-between"
          >
            <Box
              my={1}
              width={{ xs: theme.spacing(34.5), md: theme.spacing(41.5) }}
              height={{ xs: theme.spacing(9) }}
            >
              <GatsbyLink to="/">
                <Logo />
              </GatsbyLink>


            </Box>
            <Hidden mdUp>
              <Box color={colors.black}>
                <IconButton
                  aria-label="menu"
                  color="inherit"
                  onClick={() => setOpenDrawer(!openDrawer)}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
            </Hidden>
            <Hidden smDown>
              <Box>
                <Grid container spacing={6}>
                  {Links.map((L) => (
                    <Grid key={L.anchor} item>
                      <StyledLink
                        activeClass="active"
                        to={L.anchor}
                        spy
                        smooth
                        offset={-120}
                        duration={500}
                      >
                        <Box
                          fontFamily="Sofia Pro bold"
                          fontSize={theme.spacing(1.5)}
                        >
                          {L.title}
                        </Box>
                      </StyledLink>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Hidden>
          </Grid>
        </Grid>
      </Container>
      <Drawer
        anchor="top"
        open={openDrawer}
        elevation={0}
        ModalProps={{
          hideBackdrop: true,
        }}
        PaperProps={{
          style: {
            marginTop: "88px",
            background: "#EFEFEF 0% 0% no-repeat padding-box",
            backdropFilter: "blur(30px)",
            WebkitBackdropFilter: "blur(30px)",
          },
        }}
      >
        <Box display="flex" height={theme.spacing(72.5)}>
          <Grid container justify="center" alignItems="center">
            <Grid
              container
              direction="column"
              alignItems="center"
              spacing={6}
              style={{ width: "100%" }}
            >
              {Links.map((L) => (
                <Grid key={L.anchor} item>
                  <StyledLink
                    activeClass="active"
                    to={L.anchor}
                    spy
                    smooth
                    offset={-120}
                    duration={500}
                    onClick={() => setOpenDrawer(false)}
                  >
                    <Box
                      fontFamily="Sofia Pro bold"
                      fontSize={theme.spacing(1.5)}
                    >
                      {L.title}
                    </Box>
                  </StyledLink>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </MuiAppBar>
  );
};

export default Navbar;
