import theme from "../theme";
import React from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-scroll";
import styled from "styled-components";
import Links from "../const/Links"

const StyledLink = styled(Link)`
  cursor: pointer;
`


const Footer = () => (
  <React.Fragment>
    {/* <Container maxWidth="xl">
      <Box component="div" py={{ xs: 10 }}>
        <Grid justify="space-between" container spacing={2}>
          {Links.map((L) => (
            <Grid key={L.anchor} item>
              <StyledLink
                activeClass="active"
                to={L.anchor}
                spy
                smooth
                offset={-120}
                duration={500}
              >
                <Box
                  fontSize={{ xs: theme.spacing(2) }}
                  color="#2E2E2E"
                >
                  {L.title}
                </Box>
              </StyledLink>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container> */}
    <Box py={{ xs: 3, lg: 5 }} bgcolor="white">
      <Container maxWidth="xl">
        <Box>Copyright © 2023 Kamerové systémy, s.r.o.</Box>
      </Container>
    </Box>
  </React.Fragment>

);

export default Footer;
